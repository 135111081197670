.sectionWrapper {
    overflow: hidden;    
}

.templateButton {
    margin-right: 5px;
    padding-bottom: 5px;
    width: 190px;
    height: 70px;
    overflow: hidden;
    cursor: pointer;
    float: left;
    border: 1px transparent;
    img {
        width: 100%;
        overflow: hidden;
    }       
    :hover {
        border: 1px solid black;
    }
}

.stepButton {
    margin-right: 5px;
    margin-bottom: 5px;
}

#deleteStep {
    color: red;
}

.anchorSecondaryLine {
    display: block;
    margin-bottom: 10px;;
}

#stepDatePicker {
    margin-top: 10px;
    float: left;
}

#stepBlock {
    margin-top: 10px;
}

#recurringSequenceDisclaimer {
    font-size: 12px;
}

#userVarsDisclaimer {
    font-size: 12px;
    margin-top: -5px;
    font-weight: normal;
}

#userVarList {
    padding-top: 10px;
}

#recurringSequenceDaysInput {
    margin-left: 25px;
}

.leftSideBar {
    margin-top: 15px;
    margin-left: 25px;
}

.ant-collapse-expand-icon {
    margin-top: 10px;
}

#deleteUserVar {
    color: red;
    margin-left: 10px;
    cursor: pointer;
}

.backButton {
    float: left;    
    width: 100%;
    margin-top: -5px;
    margin-bottom: 15px;
}