.topBar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;

  .leftSide {
    .backButton {
      display: inline-block;
      margin-bottom: 10px;
      color: #1890ff;  // Ant Design's default link color
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #40a9ff;  // Ant Design's default link hover color
      }
    }

    .chooseRun {
      margin-top: 10px;

      .ant-select {
        margin-bottom: 10px;
      }

      a {
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .rightSide {
    // Any specific styling for the AI Analysis button
  }

}

.progressBox {
    overflow: hidden;
}

.progressBox-left {
    float: left;  
}

.progressBox-right {
    float: left;  
    padding-left: 20px;
    margin-top: 30px
}

.exportCSV {
    float: right;
    font-size: 22px;
    cursor: pointer;
}

#viewQuestions {
    color: #1890ff;
    cursor: pointer;
}

.ai-analysis-btn {
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #34d399, #10b981) !important;
  color: white !important;
  border: none !important;
  border-radius: 20px !important;
  transition: background 0.3s ease !important;
  min-width: 120px;

  &:hover, &:focus {
    color: white !important;
    background: linear-gradient(90deg, #059669, #047857) !important;
  }

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: rotate(45deg);
    transition: 0.5s;
    opacity: 0;
  }

  &:hover::before {
    animation: shimmer 1.5s infinite;
    opacity: 1;
  }

  &.loading::before {
    animation: none;
    opacity: 0;
  }
}

@keyframes shimmer {
  0% {
    transform: translate(-100%, -100%) rotate(45deg);
  }
  100% {
    transform: translate(100%, 100%) rotate(45deg);
  }
}

.ai-analysis-content {
  .ant-typography {
    margin-bottom: 16px;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  h4 {
    margin-top: 0;
  }

  p {
    margin-bottom: 16px;
  }

  ul, ol {
    margin-bottom: 16px;
    padding-left: 24px;
  }

  li {
    margin-bottom: 8px;
  }

  code {
    background-color: #f0f0f0;
    padding: 2px 4px;
    border-radius: 4px;
  }

  pre {
    background-color: #f0f0f0;
    padding: 16px;
    border-radius: 4px;
    overflow-x: auto;
  }

  blockquote {
    border-left: 4px solid #1890ff;
    padding-left: 16px;
    margin-left: 0;
    margin-bottom: 16px;
    color: #666;
  }
}

// Ensure the chart has some height
.ant-chart {
  height: 300px;
}

.ant-space {
  display: flex;
  align-items: center;

  .anticon-info-circle {
    font-size: 16px;
    margin-right: 8px;
  }
}