.navbar {

  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
  border-right: 1px solid #f0f0f0;

  .navbar-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px); // Adjust this value based on your logout button height
    overflow: hidden;
  }

  .navbar-logo-container {
    padding: 16px;
    text-align: center;
    background-color: #fff;
    z-index: 1;
    perspective: 1000px; // Add perspective for 3D effect
  }

  .navbar-logo {
    width: 80%;
    max-width: 120px;
    transition: all 0.2s;
  }

  .spin-horizontal {
    animation: spinHorizontal 2s ease-out forwards;
  }

  .navbar-menu {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
    padding-top: 0; // Ensure no top padding

    :global(.ant-menu) {
      background-color: #fff;
      border-right: none; // Remove Ant Design's default right border
    }

    :global(.ant-menu-inline) {
      height: 100%;
    }

    :global(.ant-menu-item), :global(.ant-menu-submenu-title) {
      height: auto;
      line-height: 1.5;
      padding: 12px 16px !important;
      margin: 0;
      background-color: #fff;

      &:hover {
        background-color: #f0f2f5;
      }
    }

    :global(.ant-menu-item-selected) {
      background-color: #e6f7ff;
    }

    :global(.ant-menu-item-icon) {
      vertical-align: middle;
    }
  }

  .navbar-logout-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px;
    border-top: 1px solid #f0f0f0;
    background-color: #fff;
  }

  .navbar-logout-button {
    width: 100%;
    text-align: left;
    padding-left: 24px;
  }

  .spinning-logo {
    animation: spin 2s linear infinite;
    animation-play-state: paused;

    &:hover {
      animation-play-state: running;
    }
  }

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  @keyframes spinHorizontal {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }

  // ========== for phones, we go with a horizontal navbar ========== // 
  .navbar-menu-mobile {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    border-top: 1px solid #ddd;
    padding: 10px;
    height: 400px;
    overflow-y: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  
    .ant-menu-item {
      padding: 20px;
      font-size: 14px;
      color: #333;
      width: 100%;
      text-align: left;
    }
  
    .ant-menu-item:hover {
      background-color: #f0f0f0;
    }
  }
   
  // styles that need overwritten for mobile 
  @media (max-width: 767px) {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .navbar-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 10px;
    }

    .navbar-logo-container {
      display: none;
    }

    .navbar-menu {
      display: none;
    }

    .navbar-hamburger {
      background-color: white;
      padding: 10px;
      width: 100%;
    }

    .navbar-logout-container {
      display: none;
    }
  }

}


