.catalogFiltersContainer {
    width: 100%;
    overflow: hidden;
}

.catalogFilter {
    float: left;
    display: block;
    margin-right: 10px;
}

.rewardCard {
    background-color: rgb(242,242,242);
    div {
        background-size: cover;
    }
    #cover {
        height: 140px;
    }
    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    max-width: 200px;
    min-width: 165px;
}

.rewardModalCover {
    width: 200px;
    min-width: 165px;
    margin-bottom: 25px;
}

.billingInput {
    width: 100%;
    padding: 5px;
    margin: 5px 0;
}

.billingInputSave {
    margin-top: 15px;
    width: 250px;
}

.fundingInput {
    width: 180px;
    padding: 5px;
    padding-left: 0;
    margin: 5px 0;
    margin-bottom: 10px;
}

.deleteCardButton {
    margin-left: -15px;
}

.singleRewardDisclaimer {
    font-size: 10px;
    color: grey;
}

.generalDisclaimer {
    font-size: 10px;
    color: grey;
}

.noRewardsInCatalog {
    margin: 0 auto;
    padding: 80px 0;
}