.template-button-container {
  background-color: rgb(247, 245, 245);
  padding: 20px;
  padding-top: 15px;
  border-radius: 4px;
}

.template-button {
  margin-right: 5px;
  margin-bottom: 5px;
}

.create-form-checkbox {
  font-style: italic;
  margin-top: -20px;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
