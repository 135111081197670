.birthday-page {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding: 20px;
  background-color: #f9f9f9;
  position: relative;
}

.birthday-page h1 {
  font-family: 'Cursive', sans-serif;
  color: #1e90ff;
  text-align: center;
  margin-bottom: 20px;
}

.canvas {
  width: 800px;
  height: 600px;
  border: 2px solid #ccc;
  border-radius: 10px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  float: left;
  margin-top: 20px;
}

.messages {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.toolbar {
  position: fixed;
  bottom: 60px;
  right: 20px;
  width: 340px;
  max-height: calc(100vh - 80px);
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  border-radius: 10px;
  box-shadow: 8px 8px 16px #b3b3b3, -8px -8px 16px #ffffff;
  padding: 20px;
  overflow-y: auto;
  z-index: 1000;
}

/* on smaller screens, the toolbar should be at the bottom of the page */
@media (max-width: 1175px) {
  .toolbar {
    max-height: 300px;
    overflow-y: scroll;
  }
}

button {
  padding: 10px;
  background-color: #1e90ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.drawing-section, .video-section, .text-section {
  text-align: left;
  margin-bottom: 10px;
}

.sigCanvas {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

video {
  width: 100%;
} 

.drag-handle {
  cursor: move;
  padding: 5px;
  font-size: 12px;
  color: #aaa;
  margin-top: -15px;
}

.birthday-title {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  font-family: 'Cursive', sans-serif;
  color: #333;
  text-shadow: 1px 1px 2px #fff;
  padding: 10px 0;
  background: rgba(255, 255, 255, 0.8);
  border-bottom: 2px solid #ccc;
}

.delete-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  font-size: 12px;
  visibility: visible; /* Initially hidden */
}

.element-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  word-wrap: break-word;
}

.element-container:hover .delete-icon {
  visibility: visible; /* Show on hover */
}

.emoji-section {
  margin-top: 10px;
}

.birthday-message {
  width: 800px; /* Match the canvas width */
  text-align: center;
  font-family: 'Cursive', sans-serif;
  font-size: 24px;
  color: #1e90ff;
}

.canvas-instructions {
  text-align: center;
  font-family: 'Cursive', sans-serif;
  font-size: 16px;
  font-style: italic;
  margin-top: 50px;
  color: #ccc;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent backdrop */
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.showcase-card {
  position: fixed;
  right: 20px;
  top: 20px;
  width: 320px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  background-color: #fff;
}

.showcase-card .ant-card-head {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.showcase-card .ant-card-body {
  padding: 16px;
  font-size: 14px;
}
