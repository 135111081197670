body {
  min-width: 400px!important;
}

.wrapper {
  width: 100%;
}

.loginBox {
  background-color: #fff;
  width: 320px;
  border-radius: 16px;
  margin: 0 auto 0 auto;
  border: #efefef 4px dashed;
  padding: 20px;
  padding-bottom: 30px;
  text-align: center;
  margin-top: 200px;
}

.navbar {

    top: 0;
    z-index: 100;
    width: 100%;
    height: 60px;
    
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      height: 60px;
      background-color: rgb(250,251,252);
      border-bottom: 1px solid rgb(230,230,230);
    }
  
    li {
      float: left;
      img {
        margin-top: 7px;
        margin-left: 10px;
      }
    }

}

.slackButton {
  align-items: center;
  color: #fff;
  background-color: #4a154b;
  border: 0;
  border-radius: 48px;
  display: inline-flex;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 600;
  height: 44px;
  justify-content: center;
  text-decoration: none;
  width: 224px;
}

.slackButton:hover {
  color: #fff;
}

.slackButton-svg {
  height: 16px;
  width: 16px;
  margin-right: 12px;
}

.teamsButton {
  cursor: pointer;
  border: none;
  border-radius: 48px;
  width: 224px;
  height: 44px;
  background-color: #6264A7;
  color: white;
  font-size: 16px;
  font-family: Arial, 'sans-serif';
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.warningBox {
  background-color: #ffdddd; /* Light red background */
  border-left: 6px solid #f44336; /* Red left border */
  padding: 16px; /* Some padding inside the box */
  margin: 20px 10px; /* Space on top and sides */
  color: #a94442; /* Dark red text color */
  border-radius: 4px; /* Rounded corners */
  font-size: 16px; /* Font size */
}

