.graphics-library {

  &__header-container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin: 0;
    display: flex;
    align-items: center;

    &-icon {
      font-size: 32px;
      margin-right: 10px;
    }
  }

  &__content {
    margin-top: 20px;
  }

  &__images-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__image-wrapper {
    position: relative;
  }

  &__image-actions {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__action-icon {
    cursor: pointer;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 20px;
    background-color: white;
    border-radius: 5px;
    padding: 5px;

    &--copy {
      color: blue;
    }

    &--delete {
      color: red;
    }
  }
}

.ant-message {
  margin-top: 20vh;
} 